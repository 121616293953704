import React, { SVGProps } from 'react'

export const ConnectionSmIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path d="M11.594 3.342A4.75 4.75 0 0 0 5.916 8a.75.75 0 0 0 .75.75.75.75 0 0 0 .75-.75 3.248 3.248 0 0 1 2.008-3.002 3.247 3.247 0 0 1 3.54.703 3.251 3.251 0 0 1-.491 5.002 3.246 3.246 0 0 1-1.805.547.75.75 0 0 0-.75.75.75.75 0 0 0 .75.75 4.75 4.75 0 0 0 .926-9.408Z" />
      <path d="M5.334 3.25A4.745 4.745 0 0 0 .945 6.182 4.75 4.75 0 1 0 10.084 8a.75.75 0 0 0-.75-.75.75.75 0 0 0-.75.75 3.248 3.248 0 0 1-2.008 3.002 3.247 3.247 0 0 1-3.54-.703 3.252 3.252 0 0 1 .491-5.002 3.252 3.252 0 0 1 1.807-.547.75.75 0 0 0 .75-.75.75.75 0 0 0-.75-.75z" />
    </svg>
  )
}
