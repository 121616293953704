import React, { SVGProps, FC } from 'react'
import { theme } from '../../common/theme'

export const ActiveFiltersIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {' '}
    <path d="M3 5a1 1 0 0 0-1 1 1 1 0 0 0 1 1h18a1 1 0 0 0 1-1 1 1 0 0 0-1-1Zm4 6a1 1 0 0 0-1 1 1 1 0 0 0 1 1h10a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm3 6a1 1 0 0 0-1 1 1 1 0 0 0 1 1h4a1 1 0 0 0 1-1 1 1 0 0 0-1-1z" />{' '}
    <circle cx="19" cy="5" r="4" fill={theme.colors.primary} stroke="white" strokeWidth="2" />{' '}
  </svg>
)
