import React, { SVGProps } from 'react'

export const ConnectionLgIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <path d="M14.97 5.62A6.414 6.414 0 0 0 9.188 12a1.012 1.012 0 0 0 1.014 1.012A1.012 1.012 0 0 0 11.213 12c0-.868.256-1.716.738-2.438h.002a4.385 4.385 0 0 1 4.504-1.865 4.387 4.387 0 0 1-.857 8.69 1.012 1.012 0 0 0-1.012 1.013 1.012 1.012 0 0 0 1.012 1.012 6.412 6.412 0 1 0-.63-12.793Z" />
      <path d="M8.4 5.588A6.412 6.412 0 1 0 14.812 12a1.012 1.012 0 0 0-1.013-1.012A1.012 1.012 0 0 0 12.787 12c0 .868-.256 1.716-.738 2.438h-.002a4.385 4.385 0 0 1-6.75.664A4.387 4.387 0 0 1 8.4 7.613 1.012 1.012 0 0 0 9.412 6.6 1.012 1.012 0 0 0 8.4 5.588Z" />
    </svg>
  )
}
