import React, { FC } from 'react'
import styled from 'styled-components'
import { minWidth } from 'styled-system'
import { PopperButton } from '../popper-button'
import { PopperListContainerProps, PopperListProps } from '../types'

export const PopperList: FC<PopperListProps> = ({ actions, onClose, ...widthProps }) => {
  return (
    <PopperListContainer onClose={close} {...widthProps}>
      {actions.map((action) => {
        const { name, icon, variant, onClick, disabled } = action
        const handleOnClick = () => {
          onClick && onClick()
          onClose && onClose()
        }
        if (disabled) {
          return null
        }
        return (
          <PopperItem key={name}>
            <PopperButton name={name} icon={icon} variant={variant} onClick={handleOnClick} />
          </PopperItem>
        )
      })}
    </PopperListContainer>
  )
}

const PopperListContainer = styled.ul<PopperListContainerProps>`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.grey};
  border-radius: 0.8rem;
  ${minWidth}
`

const PopperItem = styled.li`
  white-space: nowrap;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyLight};
  }
`
