import React, { SVGProps } from 'react'

export const SuitcaseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.111 3.889a2.781 2.781 0 0 0-2.777 2.777v.78H5.777A2.775 2.775 0 0 0 3 10.222v7.111a2.777 2.777 0 0 0 2.777 2.777h12.446A2.777 2.777 0 0 0 21 17.334v-7.111a2.782 2.782 0 0 0-2.777-2.778h-2.557v-.779a2.775 2.775 0 0 0-2.777-2.777Zm0 2h1.778a.779.779 0 0 1 .777.777v.78h-3.332v-.78a.78.78 0 0 1 .777-.777zM5.777 9.445h12.446a.78.78 0 0 1 .777.777v7.112a.774.774 0 0 1-.229.549.774.774 0 0 1-.548.228H5.777a.774.774 0 0 1-.548-.228.774.774 0 0 1-.229-.549v-7.111a.78.78 0 0 1 .777-.778Z" />
    </svg>
  )
}
